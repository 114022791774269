<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require('@/assets/img/pages/task.png')" alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="name"
            disabled
        >
          <template v-slot:label>
            Название задачи
          </template>
        </v-text-field>
        <div class="item-page__actions mt-1">
          <v-btn text class="evi-button-text" @click="done" v-if="!isDone && executor.id === profile.id">
            <img class="mr-2" :src="require('@/assets/img/users/green.svg')" alt="">
            Завершить задачу
          </v-btn>
          <v-btn v-if="!isDone" text class="evi-button-text" @click="removeTaskModal = true">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__description">
        <div @click="openClientPage(task.associated_client.id)" class="pointer"
             v-if="task.associated_client">
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-model="task.associated_client.name"
              disabled
          >
            <template v-slot:label>
              Связанный клиент
            </template>
          </v-text-field>
        </div>
        <v-text-field
            class="evi-text-field mb-5"
            color="#44D370"
            v-model="name"
            @change="edit"
            :disabled="isDone"
        >
          <template v-slot:label>
            Название задачи
          </template>
        </v-text-field>
        <v-textarea
            no-resize
            class="evi-textarea mb-5"
            color="#44D370"
            v-model="description"
            @change="edit"
            :disabled="isDone"
        >
          <template v-slot:label>
            Описание задачи
          </template>
        </v-textarea>
        <div class="d-flex flex-column mb-5">
          <div class="evi-datetime mr-9">
            <v-datetime-picker
                dateFormat="dd.MM.yyyy"
                clearText="Отмена"
                ok-text="Принять"
                locale="ru-ru"
                label="От"
                v-model="endDateFrom"
                :textFieldProps="fromTextFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                :disabled="isDone"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </div>
          <div class="evi-datetime">
            <v-datetime-picker
                dateFormat="dd.MM.yyyy"
                clearText="Отмена"
                ok-text="Принять"
                locale="ru-ru"
                label="До"
                v-model="endDateTo"
                :textFieldProps="toTextFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                :disabled="isDone"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </div>
        </div>
        <div class="attachments">
          <div class="attachments__headline">Вложения</div>
          <v-file-input
              chips
              multiple
              class="evi-text-field mb-5"
              label="Прикрепить файлы"
              color="#44D370"
              @change="edit"
              :disabled="isDone"
              v-model="files"
          />
          <div class="attachments__items">
            <div v-for="item in attachments" :key="item.id" class="attachments__item">
              <v-icon @click="downloadFile(item.url, item.name)" size="20" class="mr-2">mdi-download</v-icon>
              <span @click="downloadFile(item.url, item.name)" :title="item.name">{{ item.name }}</span>
              <v-icon v-if="!isDone" @click="removeFile(item.id)" class="close" size="20">mdi-close</v-icon>
            </div>
          </div>
        </div>
        <div class="item-page__added mt-11">
          <h3>Комментарии</h3>
          <Comments
              :comments="task.comments"
              :moduleItemId="task.id"
              value="tasks"
              @getData="getData"
          />
        </div>
      </div>
      <div class="item-page__responsible" v-if="hasRight('tasks.all')">
        <v-autocomplete
            class="evi-select mb-11"
            color="#44D370"
            v-model="executorId"
            :items="localUsers"
            item-text="name"
            item-value="id"
            @change="edit"
            :disabled="isDone"
            :loading="userLoading"
            @focus="loadUsers"
            :search-input.sync="userSearch"
            @input.native="searchUser(userSearch)"
            clearable
            item-color="green"
        >
          <template v-slot:label>
            Исполнитель
          </template>
          <template v-slot:append-item>
            <div v-intersect="userIntersect"/>
          </template>
          <template v-slot:item="{item}">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <div class="v-list-item__title">{{ item.name }}</div>
          </template>
          <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
          </template>
          <template v-slot:prepend>
            <v-avatar
                color="#FFFFFF"
                size="35"
                class="user-page__avatar mr-2"
                @click="openUserPage(executor.id)"
            >
              <img
                  :src="executor.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-select"
            color="#44D370"
            v-model="curatorId"
            :items="localUsers"
            item-text="name"
            item-value="id"
            @change="edit"
            :disabled="isDone"
            :loading="userLoading"
            @focus="loadUsers"
            :search-input.sync="curatorSearch"
            @input.native="searchUser(curatorSearch)"
            clearable
            item-color="green"
        >
          <template v-slot:label>
            Куратор
          </template>
          <template v-slot:append-item>
            <div v-intersect="userIntersect"/>
          </template>
          <template v-slot:append>
                    <span class="evi-select__arrow">
                        <v-icon>mdi-chevron-down</v-icon>
                    </span>
          </template>
          <template v-slot:item="{item}">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <div class="v-list-item__title">{{ item.name }}</div>
          </template>
          <template v-slot:prepend>
            <v-avatar
                color="#FFFFFF"
                size="35"
                class="user-page__avatar mr-2"
                @click="openUserPage(curator.id)"
            >
              <img
                  :src="curator.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <v-dialog
        v-model="removeTaskModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление задачи "{{ task.name }}"
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить задачу?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeTaskModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeTask"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Comments from "../../components/сommon/Comments";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {hasRight} from '@/utils/access/hasRight';
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "TaskPage",
  components: {Comments},
  mixins: [OpenPagesMixin, UserAutocompleteRequestsMixin],
  data: () => ({
    name: '',
    description: '',
    endDateFrom: '',
    endDateTo: '',
    files: [],
    attachments: [],
    curatorId: null,
    executorId: null,
    curator: {},
    executor: {},
    removeTaskModal: false,
    fromTextFieldProps: {
      color: '#44D370',
    },
    toTextFieldProps: {
      color: '#44D370',
    },
    datePickerProps: {
      color: '#44D370',
      firstDayOfWeek: 1,
    },
    timePickerProps: {
      color: '#44D370',
      format: "24hr",
    },
  }),
  computed: {
    ...mapState("tasks", ['task',]),
    ...mapState("user", ["profile",]),
    ...mapState("common", ["loading"]),
    endDateFromFormated() {
      return this.$moment(this.endDateFrom).format('YYYY-MM-DD HH:mm:ss');
    },
    endDateToFormated() {
      return this.$moment(this.endDateTo).format('YYYY-MM-DD HH:mm:ss');
    },
    isDone() {
      return !!this.task.marked_as_done_at;
    },
    localUsers() {
      let array = [];
      array.push(this.curator);
      if (this.curator.id !== this.executor.id) {
        array.push(this.executor);
      }
      this.users.forEach(i => {
        if (i.id !== this.curator.id && i.id !== this.executor.id) {
          array.push(i);
        }
      });
      return array;
    },
    localClients() {
      return [this.task.associated_client]
    }
  },
  methods: {
    ...mapActions({
      getTask: 'tasks/getTask',
      editTask: 'tasks/editTask',
      deleteTask: 'tasks/deleteTask',
      doneTask: 'tasks/doneTask',
      addFile: 'tasks/addFile',
      deleteFile: 'tasks/deleteFile',
    }),
    hasRight: hasRight,
    getData() {
      let id = this.$route.params.id;
      this.getTask(id);
    },
    edit() {

      if (this.files.length) {
        //Если с файлами
        this.files.forEach((item) => {
          let formData = new FormData();
          formData.append('attachment', item);
          this.addFile({id: this.task.id, formData: formData});
        });
      } else {
        this.editTask({
          name: this.name || this.task.name,
          description: this.description || this.task.description,
          executor_id: this.executorId || this.task.executor.id,
          curator_id: this.curatorId || this.task.curator.id,
          end_date_from: this.endDateFromFormated || this.task.end_date_from,
          end_date_to: this.endDateToFormated || this.task.end_date_to,
          id: this.task.id,
        });
      }
    },
    removeTask() {
      this.deleteTask(this.task).then(() => {
        this.removeTaskModal = false;
        this.$router.push({path: '/tasks'});
      });
    },
    removeFile(item) {
      this.deleteFile({id: this.task.id, fileId: item})
          .then(() => {
            let id = this.$route.params.id;
            this.getTask(id);
          });
    },
    downloadFile(fileUrl, fileName) {
      let a = document.createElement("a");
      a.href = fileUrl;
      a.setAttribute("download", fileName);
      a.setAttribute("target", '_blank');
      a.click();
    },
    done() {
      this.doneTask(this.task).then(() => {
        this.getData();
      })
    },
  },
  watch: {
    task(val) {
      this.name = this.isDone ? `${val.name} (Завершено)` : val.name;
      this.description = val.description;
      this.endDateFrom = this.$moment(val.end_date_from).utcOffset(-3).toDate();
      this.endDateTo = this.$moment(val.end_date_to).utcOffset(-3).toDate();
      this.attachments = val.attachments;
      this.curatorId = val.curator.id;
      this.executorId = val.executor.id;
      this.curator = val.curator;
      this.executor = val.executor;
      this.files = [];
    },
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
    endDateFromFormated(val) {
      if (this.task && val !== this.task.end_date_from) {
        this.edit();
      }
    },
    endDateToFormated(val) {
      if (this.task && val !== this.task.end_date_to) {
        this.edit();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.attachments {
  &__headline {
    font-weight: normal;
    font-size: 11px;
    color: #464A53;
    margin-bottom: 13px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    background: #F0F0F0;
    border-radius: 3px;
    margin-right: 12px;
    margin-bottom: 7px;
    padding: 10px 45px 10px 12px;
    max-height: 40px;
    height: 40px;
    text-decoration: none;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    color: #808386;
    position: relative;
    cursor: pointer;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 241px;
    }

    .close {
      position: absolute;
      right: 12px;
      top: 10px;
    }

  }

  .evi-text-field {
    width: 100% !important;
  }
}

.item-page {
  &__description {
    max-width: 60%;
    border-right: 1px solid #EBEBEC;
    padding-right: 55px;
    margin-right: 55px;
  }

  &__responsible {
    .user-page__avatar {
      cursor: pointer;
      margin-top: -5px;
    }
  }

  &__comments {
    padding-top: 0;
  }
}
</style>
